@import '../style.scss';

.header {
	background-color: white;
	position: fixed;
	position: sticky;
	z-index: 5;
	top: 0;
	left: 0;
	width: 100%;
	border-bottom: 1px solid #3d3d3d;

	&-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 0;

		@include breakpoint(ipad-land) {
			padding: 0;
		}
	}

	&-mobilenav {
		@include breakpoint(ipad-land) {
			display: none;
		}
	}
}

.header_nav {
	display: none;

	@include breakpoint(ipad-land) {
		display: flex;
		padding: 26px 0;
	}
}

.header_navlink {
	position: relative;
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	color: #3d3d3d;

	font-family: 'Lora';
	font-size: 20px;
	font-weight: 700;
	letter-spacing: 0.5px;
	transition: text-shadow 0.2s ease-out, color 0.2s ease-out;

	@include breakpoint(ipad-land) {
		color: #3d3d3d;
		font-size: 16px;
		font-weight: 300;
	}

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		bottom: -2px;
		width: 100%;
		height: 1px;
		background-color: #3d3d3d;
		transform: translateX(-50%) scaleX(0);
		transition: transform 0.25s ease-out;
	}

	@include breakpoint(desktop) {
		&:hover {
			&:after {
				transform: translateX(-50%) scaleX(0.8);
			}
		}
	}

	&.is-active {
		color: #3d3d3d;

		@include breakpoint(desktop) {
			text-shadow: 0 0 0.1px #3d3d3d;
		}

		&:after {
			transform: translateX(-50%) scaleX(1);
		}
	}

	& + & {
		margin-top: 15px;

		@include breakpoint(ipad-land) {
			margin-top: 0;
			margin-left: 32px;
		}
	}
}

.header_hamburger {
	position: relative;
	padding: 8px;
	cursor: pointer;
	@include breakpoint(ipad-land) {
		display: none;
	}
}

.header_mobilenav {
	height: 100%;
	display: flex;
	flex-direction: column;

	&-menu {
		width: 100%;
		flex: 1 0 auto;
		padding: 85px 0;

		&-items {
			align-items: center;
			flex-direction: column;
		}
	}

	&-navigation {
		&-items {
			padding-top: 40px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			&-item {
			}
		}
	}
}

.header-mobilenav-root {
	// Dirty fix in order toremove dialog when you resize the page
	display: block;

	@include breakpoint(ipad-land) {
		display: none;
	}
}

.header-mobilenav-paper {
	box-shadow: none !important;
	border-top: 1px solid #f9f9f9 !important;
}

.header_logo {
	font-family: 'Lora', serif;
	width: 35px;
	display: flex;
	align-items: center;
	svg {
		width: 35px;
	}
}
