@import '../style.scss';

.gear {
  &-columns {
    display: flex;
    flex-direction: column;

    @include breakpoint(ipad-land) {
      flex-direction: row;
    }

    &-column {
      flex: 1 1 100%;

      & + & {
        margin-top: 32px;
      }

      @include breakpoint(ipad-land) {
        margin-top: 0;
      }

      &.mod-image {
        opacity: 0;
        transition: opacity 2s ease-out;

        &.is-loaded {
          opacity: 1;
        }
      }

      &:first-child {
        padding-right: 8px;
      }

      &:last-child {
        @include breakpoint(ipad-land) {
          padding-left: 8px;
        }
      }
    }
  }
}

.gear_item {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .theme_h2 {
    font-family: 'Lora', sans-serif;
    font-size: 24px;
    line-height: 30px;
  }
  .theme_p {
    font-size: 16px;
    display: inline-block;
    font-family: 'Fira sans', sans-serif;
    line-height: 20px;
  }

  &-info,
  &-title {
    &.is-link {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.15);
        transition: transform 0.2s ease-out;
        transform-origin: left;
      }

      &:hover {
        &:after {
          transform: scaleX(0);
        }
      }
    }
  }
}
