@import '../style.scss';

.error {
	&-content {
		text-align: center;

		&-text {
			margin-top: 25px;
		}

		&-button {
			margin-top: 25px;

		}
	}
}