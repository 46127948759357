@import '../style';

.footer {
  margin-top: 40px;

  &-container {
    position: relative;
    display: flex;
    padding: 40px 0 80px;
    flex-direction: row;
    justify-content: space-between;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 1px;
      width: 100%;
      background-color: #d3d3d3;
    }

    &-info {
      display: flex;
      flex-direction: column;

      &-row {
        display: flex;
        flex-direction: column;
        & + & {
          margin-top: 8px;
        }

        @include breakpoint(ipad-land) {
          flex-direction: row;
          & > * {
            margin-left: 8px;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }

    &-logos {
      display: inline-flex;

      a {
        height: 16px;;
        display: flex;

        + a {
          margin-left: 8px;
        }
      }
      .imdb-logo {
        filter: grayscale(1);
      }
    }
  }

  a {
    transition: opacity 0.15s ease-out;

    &:hover {
        opacity: 0.8;
    }
  }

  .theme_p {
    font-family: 'Lora', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 8px 0;

    @include breakpoint(ipad-land) {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 13px;
      line-height: 17px;

      & + .theme_p {
        margin-left: 8px;
      }
    }
  }
}
