.layout {
	display: flex;
	overflow: hidden;
	min-height: 100vh;
	flex-direction: column;
	justify-content: space-between;
	&-content {
		padding: 80px 0 80px;
		flex: 1 1 auto;
	}
}