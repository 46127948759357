@import './styles/mixins/m-placeholder.scss';
@import './styles/mixins/m-cols.scss';

@function px($value) {
	@return $value / 16 * 1rem;
}

$facebook: #3b5999;
$twitter: #55acee;
$linkedin: #0077b5;
$instagram: #e4405f;

$small: 480px;
$tablet: 600px;
$ipad-port: 768px;
$ipad-land: 1024px;
$desktop: 1200px;
$large: 1600px;

@mixin ie-only() {

	@media all and (-ms-high-contrast: none),
	(-ms-high-contrast: active) {
		@content;
	}
}

@mixin breakpoint($breakpoint: '') {
	@if $breakpoint==small {
		@media (min-width: $small) {
			@content;
		}
	}

	@else if $breakpoint==tablet {
		@media (min-width: $tablet) {
			@content;
		}
	}

	@else if $breakpoint==ipad-port {
		@media (min-width: $ipad-port) {
			@content;
		}
	}

	@else if $breakpoint==ipad-land {
		@media (min-width: $ipad-land) {
			@content;
		}
	}

	@else if $breakpoint==desktop {
		@media (min-width: $desktop) {
			@content;
		}
	}

	@else if $breakpoint==large {
		@media (min-width: $large) {
			@content;
		}
	}

	@else {
		@error "Unknown breakpoint #{$breakpoint}.";
	}
}