.files_item {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-title {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    .theme_h2 {
      font-family: 'Lora', sans-serif;
      font-size: 24px;
      line-height: 30px;
    }
  }

  .theme_p {
	font-size: 16px;
	display: inline-block;
	font-family: 'Fira sans', sans-serif;
	line-height: 20px;
  }

  &-info,
  &-title {
    &.is-link {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.15);
        transition: transform 0.2s ease-out;
        transform-origin: left;
      }

      &:hover {
        &:after {
          transform: scaleX(0);
        }
      }
    }
  }
}
