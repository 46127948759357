@import './style';

$color: #3d3d3d;

.theme_container {
	$width: 1000px;
	$padding: 25px;
	width: 100%;
	margin: 0 auto;
	max-width: $width + 2 * $padding;
	padding: 0 $padding;
}

.theme_h1 {
	position: relative;
	padding-bottom: 12px;
	margin-bottom: 60px;
	font-weight: 600;
	font-size: 32px;
	line-height: 40px;
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		width: 60px;
		height: 2px;
		background-color: rgba(0, 0, 0, .6);
		transform: scaleX(0);
		transform-origin: left;
		transition: transform 1.5s ease-out;
	}

	&.is-loaded {
		&:before {
			transform: scaleX(1)
		}
	}
}

.theme_h1,
.theme_h2,
.theme_h3,
.theme_h4,
.theme_h5 {
	font-family: 'Lora';
	position: relative;
	word-wrap: break-word;
}

.theme_h2 {
	font-family: "Fira sans", sans-serif;
	font-size: 20px;
	font-weight: 600;
}

.theme_p {	
	line-height: 1;
}

.theme_wysiwyg {

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: bold;
	}

	*+* {
		margin-top: 30px;
	}

	ol,
	ul {
		padding-left: 20px;

		li+li {
			margin-top: 5px;
		}
	}

	a {
		color: $color;
		border-bottom: 1px solid transparent;
		transition: all 0.3s ease-out;

		&:hover {
			color: darken($color, 5%);
			border-color: darken($color, 5%);
		}
	}

	img {
		height: auto;
		display: block;
		max-width: 100%;
	}

	table {
		width: auto;
		display: block;
		overflow-x: auto;
		border-spacing: 0;
		border-collapse: collapse;
	}
}

.theme_button {
	color: white;
	cursor: pointer;
	background-color: $color;
	display: inline-block;
	vertical-align: top;
	padding: 10px 20px;
	border: 1px solid transparent;
	transition: all 0.3s ease-out;

	&:hover {
		background-color: darken($color, 5%);
	}

	//MODS
	&.mod-rounded {
		border-radius: 3px;
	}

	&.mod-round {
		padding: 8px 25px;
		border-radius: 50px;
	}

	&.mod-outline {
		color: $color;
		border-color: rgba($color, 0.5);
		background-color: transparent;

		&:hover {
			color: white;
			background-color: $color;
		}
	}
}

.theme_buttontext,
.theme_buttonicon {
	line-height: initial;
	display: inline-block;
	vertical-align: middle;

	&+& {
		margin-left: 15px;
	}
}

.theme_buttonicon {
	font-size: 16px;
}

.theme_link {
	color: $color;
	text-decoration: underline;
	transition: color 0.25s ease-out;

	&:hover {
		color: darken($color, 5%);
	}
}

.theme_linktext,
.theme_linkicon {
	line-height: initial;
	display: inline-block;
	vertical-align: middle;

	&+& {
		margin-left: 15px;
	}
}

.theme_linkicon {
	font-size: 16px;
}