@import '../style.scss';

.video {
	position: relative;
	overflow: hidden;
	max-width: 100%;
	height: 0;
	padding-bottom: calc(100% / (16 / 9));
	transition: opacity 0.5s ease-in-out;

	&-playbutton {

		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&.mod-ratio {
		padding-bottom: calc(100% / (595 / 431));
	}

	&-wrapper {
		background: #3d3d3d;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
}