@import '../style.scss';

.work_slider {
  margin-top: 24px;
  margin-left: -8px;

  &-controller {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100;
    position: relative;

    &-item {
      opacity: 0.2;
      transition: opacity 0.2s ease-in-out;

      &.is-active {
        cursor: pointer;

        opacity: 1;
      }

      & + & {
        margin-left: 24px;
      }
    }

    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.work_videoitem {
  .theme_wysiwyg {
    font-family: 'Fira sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
  .theme_h3, .theme_h4 {
    font-family: 'Fira sans', sans-serif;
    font-size: 19px;
    font-weight: 700;
    line-height: 25px;
  }
  .background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: auto;
  }
  overflow: hidden;
  position: relative;
  display: flex;
  height: 240px;
  padding: 0 8px;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  min-width: 100%;
  max-width: 400px;

  @include breakpoint('tablet') {
    padding: 0 8px;

    &:last-child {
      padding: 0 0 0 8px;
    }

    &:first-child {
      padding: 0 8px 0 0;
    }

    min-width: 430px;
  }

  &:hover {
    .work_videoitem {
      &-playbutton {
        svg {
          path {
            fill: #d3d3d3;
          }
        }

        transform: scale(0.9);
      }

      &-container {
        &:before {
          opacity: 0.4;
        }
      }
    }
  }

  &-playbutton {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    pointer-events: none;
    transition: transform 0.6s ease-out;

    svg {
      width: 50px;
      height: 50px;

      path {
        transition: fill 0.2s ease-out;
      }
    }
  }

  &-container {
    position: relative;
    z-index: 0;
    width: 100%;

    opacity: 0;
    transition: opacity 0.4s ease-out;
    &-background {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      transition: opacity 0.5s ease-out;

      .background {
        height: 100%;
        object-fit: cover;
      }
      &.is-loaded {
        opacity: 1;
      }
    }
    &.is-loaded {
      opacity: 1;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
      transition: opacity 0.4s ease-out;
      opacity: 0.5;
    }

    &-heading {
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding: 8px;
      color: white;
      z-index: 1;
      transition: opacity 0.2s ease-out 0.2s;

      &-title {
        z-index: 1;
        font-family: 'Fira sans', sans-serif;
      }

      &-year {
        z-index: 1;
        margin-left: 5px;
      }
    }

    &-info {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      align-items: flex-end;
      transition: opacity 0.2s ease-out;
      padding: 8px;
      color: white;
    }

    &.mod-info {
      &:hover {
        .work_videoitem-container-heading {
          opacity: 0;
          transition: opacity 0.2s ease-out;
        }

        .work_videoitem-container-info {
          opacity: 1;
          transition: opacity 0.2s ease-out 0.2s;
        }
      }
    }

    &:hover {
      &:after {
        opacity: 0.9;
      }
    }

    &.is-active {
      opacity: 1;
      transform: scale(1);

      &:before {
        opacity: 1;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: linear-gradient(
        0,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(225, 255, 255, 0.2) 100%
      );
      transition: opacity 0.2s ease-in-out;
    }

    &.mod-background {
      &:after {
        background-image: linear-gradient(
          0,
          rgba(0, 0, 0, 0.8) 0%,
          #fdfdfd 100%
        );
      }
    }
  }
}

.work-overview-category {
  & + .work-overview-category {
    margin-top: 40px;
  }
}
