@import './Fonts.scss';
@import './style';

body {
	font-family: 'Fira sans', sans-serif;
	font-weight: 300;
	color: #3d3d3d
}

* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: border-box;
	}
}

a {
	color: inherit;
	cursor: pointer;
	text-decoration: none;

	img {
		border: none;
	}
}

button,
input,
select,
textarea {
	font: inherit;
	border: none;
	border-radius: 0;
	background: none;

	&:focus {
		outline: 0;
	}
}

textarea {
	resize: vertical;
}

iframe {
	border: none;
}