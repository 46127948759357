.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  z-index: 6;
  background: linear-gradient(
    to right,
    black 25%,
    grey 75%,
  );
  transform: scale(0);
  transform-origin: left;
  animation: load 1s ease-out;

  &-logo {
    height: 80px;
    width: 80px;
  }
  &.is-loading {
    animation-play-state: running;
  }
}

@keyframes load {
  to {
    transform: scaleX(1);
  }
}
