@import '../style.scss';

.about {
	&-container {
		display: flex;
		align-items: flex-start;
		flex-direction: column;

		@include breakpoint('tablet') {
			flex-direction: row;
		}

		&-image {
			margin: 0 auto;
			opacity: 0;

			transition: opacity 2s ease-out;

			@include breakpoint('tablet') {
				width: 100%;
			}

			@include breakpoint('ipad-land') {
				width: 50%;
			}

			&.is-loaded {
				opacity: 1;
			}
		}

		&-description {
			position: relative;
			background-color: white;
			margin-top: 24px;

			@include breakpoint('tablet') {
				padding: 48px 24px;
				margin-left: -70px;
				margin-top: 100px;
				&:before,
				&:after {
					content: '';
					position: absolute;
					left: 0;
					width: 200px;
					max-width: 100%;
					height: 1px;
					background-color: #d3d3d3;
					transform: scaleX(0);
					transform-origin: left;
					transition: transform 2s ease-out;
				}
			}

			&:before {
				bottom: 0;
			}

			&:after {
				top: 0;
			}

			&.is-loaded {
				&:before,
				&:after {
					transform: scaleX(1);
				}
			}
		}
	}
	.theme_wysiwyg {
		font-family:'Fira sans', sans-serif;
		font-weight: 300;	
		font-size: 16px;
		line-height: 24px;
	}
}
