@font-face {
    font-family: 'Lora';
    src: url('../assets/fonts/Lora-Regular.woff2') format('woff2'),
        url('../assets/fonts/Lora-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../assets/fonts/FiraSans-Bold.woff2') format('woff2'),
        url('../assets/fonts/FiraSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../assets/fonts/FiraSans-Light.woff2') format('woff2'),
        url('../assets/fonts/FiraSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

