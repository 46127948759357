@import '../style';

.cookiewarning {
  background-color: white;
  position: fixed;
  z-index: 1;
  bottom: 20px;
  right: 20px;
  left: 20px;
  padding: 25px;
  transform: translateY(0);
  transition: transform 1.5s ease-out;
  backface-visibility: hidden;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  font-family: 'Fira sans', sans-serif;

  @include breakpoint('tablet') {
    left: unset;
  }

  &.is-hidden {
    transform: translateY(150%);
  }

  &-content {
    color: white;
    max-width: 400px;
    margin-bottom: 20px;
    color: black;
  }
}
