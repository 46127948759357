@import '../style.scss';

.home {
  &-title {
    font-size: 32px;
  }
  &-videos {
    padding-top: 40px;
    &-container {
      box-shadow: inset 0px 0px 0px 8px #ffffff;
      position: relative;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.4s ease-out 0.2s;
      &:hover {
        .home-videos-container-playbutton {
          transform: scale(0.9);

          &.is-loaded {
            transform: scale(0);
          }
        }
      }

      &-playbutton {
        z-index: 3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 50px;
        height: 50px;
        pointer-events: none;
        transition: transform 0.6s ease-out;

        &.is-loaded {
          transform: scale(0);
        }

        svg {
          width: 50px;
          height: 50px;
        }
      }

      &-startscreen {
        z-index: 2;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: #3d3d3d;
        opacity: 1;
        transition: opacity 0.2s ease-out;
        pointer-events: none;
        &-title {
          font-size: 34px;
          color: white;
          font-family: 'Lora';
          text-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
          position: absolute;
          bottom: 24px;
          left: 24px;
          right: 24px;
          transition: opacity 0.5s ease-out;
        }

        img {
          width: 100%;
          height: 100%;
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
        }
        .slide-enter {
          opacity: 0;
        }
        .slide-enter-active {
          opacity: 1;
          transition: opacity 0.3s ease-in-out;
        }
        .slide-exit {
          opacity: 1;
        }
        .slide-exit-active {
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }
      }
      &:hover {
        background-color: red;
        .home-videos-container-startscreen-title {
          opacity: 0.4;
        }
      }
      &.is-pictureloaded {
        opacity: 1;
      }
      &.is-loaded {
        > .home-videos-container-startscreen {
          opacity: 0;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    &-navigation {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      margin-left: -16px;

      &-thumbnail {
        position: relative;
        cursor: pointer;
        transition: transform 0.2s ease-out;
        &-image {
          display: block;
          width: auto;
          height: 100%;
          object-fit: cover;
          max-width: 200px;
          max-height: 100px;
        }
        &-playbutton {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        & + & {
          @include breakpoint(ipad-land) {
            margin-left: 8px;
          }
        }

        &:after {
          content: '';
          opacity: 0;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background-image: linear-gradient(
            0,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(225, 255, 255, 0.2) 100%
          );
          transition: opacity 0.2s ease;
        }
        &:before {
          content: '';
          position: absolute;
          left: 4px;
          bottom: 4px;
          top: 4px;
          right: 4px;
          opacity: 0;
          border: white 1px solid;
          pointer-events: none;
          transition: opacity 0.3s ease-out;
        }
        &:hover {
          &:before {
            opacity: 0.2;
          }
        }
        &.is-active {
          cursor: initial;
          pointer-events: none;

          &:after {
            opacity: 0.3;
          }
          &:before {
            opacity: 1;
          }
          &:hover {
            &:before {
              opacity: 0.8;
            }
          }
        }

        &:hover {
          &:after {
            opacity: 0.2;
          }
        }
        &-title {
          position: absolute;
          bottom: 8px;
          left: 10px;
          right: 8px;
          font-size: 14px;
          color: white;
          text-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}

.home_slider {
  &-item {
    position: relative;
    min-width: 100%;
  }
  &-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &-controller {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100;
    position: relative;

    &-item {
      opacity: 0.2;
      transition: opacity 0.2s ease-in-out;

      &.is-active {
        cursor: pointer;

        opacity: 1;
      }

      & + & {
        margin-left: 24px;
      }
    }

    svg {
      height: 30px;
      width: 30px;
    }
  }
}
